.registerPlayerContainer
  .registerPlayerHeader
    padding: 10px
  body
    color: white
    background: #3a3a3a
    font-size: 13px

  // .navbar
  //   width: 100%

  .pageTitle
    font-family: $poppinsRegularFont !important
    font-size: 14px
    text-align: center
    margin-top: .4rem
    margin-left: 20px

  .backBtn
    color: white
    font-size: 1.5rem
    left: 0
    margin-right: 2rem
    display: inline-block

  h4
    color: white
    padding: .2rem
    font-family: $poppinsRegularFont

  // .container
  //   margin: 0
  //   display: flex
  //   margin-right: 0

  // #addColumn
  //   padding: 1rem 2rem .7rem

  // .countCont, .addBtnCont
  //   width: 50%
  //   padding: 0
  //   margin: 0

  // .addBtn
  //   width: 60px
  //   margin-top: -5px

  .table-wrapper
    background: #fff
    border-radius: 3px
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05)

  .tableTitle
    display: flex
    justify-content: space-between
    margin: 15px 0 0 0
    h6
      font-family: $poppinsMediumFont
      color: #fff
      margin: 8px 0
      font-size: 13px
    .addBtn
      font-family: $poppinsRegularFont
      font-size: 12px
    .btn
      color: #fff
      // float: right
      font-size: 12px
      border: none
      outline: none !important
      margin-left: 10px
    .btn-danger
      background-color: #B31013

      span
        float: left
        margin-top: 2px

  // table
  //   table-layout: fixed

  //   &.table
  //     tr
  //       th, td
  //         border-color: #e9e9e9
  //         vertical-align: middle
  //         font-family: $poppinsRegularFont
  //         align-items: center
  //         align-content: center

  //     th
  //       border-color: #e9e9e9
  //       padding: 10px 15px
  //       vertical-align: middle
  //       color: #e9e9e9
  //       background: #444a52

  //     tr th
  //       &:last-child
  //         width: 25%

  //       &:nth-child(2)
  //         width: 38%

  //     td:last-child i
  //       font-size: 20px
  table.playerTable
    color: #fff
    thead
      background-color: #383C43
      font-family: $poppinsRegularFont
      font-size: 12px
      text-align: center
      border: 0
      th
        border: 0
    tbody
      border: 0
      text-align: center
      font-size: 13px
      td
        border: 0
        &:nth-child(1)
          font-family: $poppinsMediumFont
        &:nth-child(2)
          font-family: $poppinsLightFont
          padding: 11px 0
      .noMatches
        color: #fff
        font-family: $poppinsRegularFont
        font-size: 12px
        margin: 15px 55%
        text-align: center
        width: 100%
          

  .searchContainer
    display: flex
    justify-content: center
    align-items: center

  // .searchbar
  //   display: flex
  //   justify-content: center
  //   align-items: center
  //   position: relative

  .input
    padding: 10px
    top: 0
    width: 2rem
    height: 2rem
    color: #000
    transition: 0.4s ease
    background-color: #fff

  .searchBtn
    position: absolute
    // top: 60%
    // transform: translateY(-60%)
    font-size: 22px
    right: 25px
    margin-top: 5px
    // color: white
    // cursor: pointer
    // background-color: transparent
    // border: none
    // right: .3rem
    // float: right

  .input
    border-radius: 20px
    border: none
    background-color: #fff

    &.active
      width: 300px
      border: none
      background-color: transparent
    &::placeholder
      font-family: $poppinsRegularFont
      font-size: 12px

  input:focus
    outline: none
    background-color: #fff


  .noPlayerSection
    margin: 40% auto
    text-align: center
    .noPlayerIcon
      width: 65px
      height: auto
    .noPlayerMessage
      line-height: 5px
      margin-top: 20px
      p
        color: #fff
        font-family: $poppinsRegularFont
        font-size: 12px

  @media screen and (max-width: 500px)
    .tableTitle
      width: 100%

    .input.active
      width: 100%
      border: none
      background-color: #fff

    .tempHiddenTitle
      display: none

  @media screen and (max-width: 400px)
    .input.active
      width: 100%
      margin-right: 0
      border: none
      background-color: #fff

    .tempHiddenTitle
      display: none

    // table.table th
    //   border-color: #e9e9e9
    //   padding: 10px 5px
    //   vertical-align: middle
    //   color: #e9e9e9
    //   background: #444a52
    //   font-family: $poppinsRegularFont

  @media screen and (max-width: 360px)
    .input.active
      width: 300px
      margin-right: 0
      border: none
      background-color: #fff

    .tempHiddenTitle
      display: none

    // table.table
    //   tr
    //     th, td
    //       border-color: #e9e9e9
    //       vertical-align: middle
    //       font-family: $poppinsRegularFont
    //       align-items: center
    //       align-content: center
    //       font-size: 15px

    //   th
    //     border-color: #e9e9e9
    //     padding: 10px 5px
    //     vertical-align: middle
    //     color: #e9e9e9
    //     // background: #444a52
    //     font-family: $poppinsRegularFont

  .displayNameCont
    display: flex
    flex-direction: row

  .displayNameIcon
    margin-right: .5rem
    margin-left: .5rem
    font-size: 18px
    .playerIcon
      color: #535963

  .displayName
    margin-top: 5px
    position: flex
    width: 150px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .material-icons
    margin-right: 5px
    vertical-align: middle

  .btnIcons
    margin: 5px
    .actionIcon
      color: #fff
      width: 19px
      height: 17px

  p
    color: red
    font-size: 12px

  /* Testing

  .swal-wide
    width: 850px !important

  .toast-success
    background-color: #d813c8 !important

  .toast-warning
    background-color: #357e45 !important

  .toast-error
    background-color: #3533a7 !important


  /* Add modal
.player-modal
  .modal-dialog
    .modal-content
      .modal-header
        border: 0
        .modalTitle
          color: black
          font-family: $poppinsMediumFont!important
          // font-weight: bold
          font-size: 16px!important
      .modal-body
        .formInput
          padding: 1.5rem
          margin-top: .5rem
          margin-bottom: .5rem
          width: 100%
          height: 2rem

        .saveBtn
          // width: 100%
          border-color: #B31013
          background-color: #B31013
          font-family: $poppinsRegularFont
          font-size: 14px
          // padding: .8rem
          // margin-right:0
        .cancelBtn
          background-color: transparent
          color: #B31013
          font-family: $poppinsRegularFont
          font-size: 14px
          border: 0

        .btn-block
          width: 100%