.fight-details-video-container
    .live-streaming-video-container
        width: 100%
        .iframe-container
            height: 77px
            
    .fight-details-container
        width: 100%
        padding: 10px
        height: 77px
        text-align: center
        .fight-details-number
            color: #fff
            font-family: $poppinsMediumFont
            font-size: 15px
            margin-bottom: 2px
            margin: 0
            padding-top: 10px
        .fight-details-versus
            color: #fff
            font-family: $poppinsLightFont
            font-size: 11px
            margin-bottom: 0
            span.text-yellow
                color: #F2C301
        .fight-details-status-container
            padding-top: 8px
            // display: flex
            // justify-content: space-between
            .fight-details-betting-status
                .badge
                    background-color: #fff
                    padding: 5px 8px
                    border-radius: 10px
                    font-size: 10px
                    font-family: $poppinsMediumFont
                .badge-green
                    color: #00D22F
                .badge-red
                    color: #EE0000
            .fight-details-team-label, .fight-details-betting-status
                color: #fff
                font-family: $poppinsRegularFont
                font-size: 10px
                margin-bottom: 0
        .fight-details-number-winner
            color: #fff
            font-family: $poppinsRegularFont
            font-size: 16px
            margin-bottom: 0
        .fight-details-winner
            color: #fff
            font-family: $poppinsMediumFont
            font-size: 26px
            margin-bottom: 0
.fight-details-video-container.flex
    display: flex
.fight-details-announcement-banner
    background-color: #000
    color: #fff
    font-family: $poppinsMediumFont
    height: 28px
    display: flex
    marquee
        font-family: $poppinsMediumFont
        font-size: 14px
        padding: 4px 0
.red-bg
    background-color: #EE0000
.blue-bg
    background-color: #0B4491
.green-bg
    background-color: #27A844
.cancel-bg
    background-color: #5D5F64