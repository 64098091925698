.navbar.bg-dark
    background-color: #303337!important
    height: 52px
    .logo
        width: 100px
        height: auto
        margin-bottom: 10px
    .navbar-icons
    .navbar-user-points-container
        display: flex
        .navbar-text
            text-align: right
        p.navbar-current-points-value
            color: #FFCC00
            font-family: $poppinsRegularFont
            font-size: 16px
            margin-bottom: 0
        p.navbar-current-points-label
            color: #ECEDF1
            height: 9px
            font-family: $poppinsRegularFont
            font-size: 7px
        .navbar-points-icon
            width: 42px
            height: auto
            margin-bottom: 8px
            cursor: pointer
    .navbar-left-side
        display: flex
        margin-right: 15%
        .navbar-brand
            padding: 0
        .navbar-brand.navbar-btn-back
            color: #777A7D
        .navbar-page-name
            color: #fff
            font-family: $poppinsRegularFont
            font-size: 14px
            margin: 8px 0
    .navbar-right-side
        .navbar-btn-search
            color: #fff
            margin: 8px 10px
