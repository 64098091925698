.bet-types-container
    background-color: #303337
    color: #fff
    padding: 12px 10px
    height: 100px
    text-align: center
    .bet-types-btn-container
        display: flex
        justify-content: space-around
    .bet-types-btn-meron, .bet-types-btn-draw, .bet-types-btn-wala
        font-family: $poppinsMediumFont
        font-size: 12px
        width: 102px
        height: 36px
    .bet-types-btn-meron
        background-color: $meronColor
    .bet-types-btn-draw
        background-color: $drawColor
    .bet-types-btn-wala
        background-color: $walaColor
    .bet-types-total-label
        color: #FFCC00
        font-family: $poppinsBoldFont
        font-size: 14px
        margin-top: 8px
        margin-bottom: 0
    .bet-types-payout-label
        font-family: $poppinsMediumFont
        font-size: 10px
    .bet-types-draw-wins-label
        font-family: $poppinsMediumFont
        font-size: 12px
        margin-top: 8px
        margin-bottom: 5px
    .bet-types-draw-wins-info
        font-family: $poppinsRegularFont
        font-size: 6.5px
    .disabled
        color: #fff
        background-color: #B7B7B7
        border-color: #B7B7B7!important

.bet-types-announcement-container
    display: flex
    justify-content: space-between
    .bet-types-player-count
        width: 45%
        color: #fff
        font-family: $poppinsMediumFont
        font-size: 10px
        padding: 5px
        background-color: #1E2024
        p
            margin-bottom: 0
        .addplayericon
            color: #dc3545
            font-size: 15px
    .bet-types-announcement-banner
        width: 60%
        background-color: #B31013
        color: #fff
        font-family: $poppinsLightFont
        font-size: 10px
        padding: 5px
        text-align: center
        p
            margin-bottom: 0
    .bet-types-add-player
        color: #fff
        font-size: 11px
        font-family: $poppinsMediumFont
        text-align: center
        text-decoration: none
        padding: 0 17px
    .bet-types-add-player.disabled
        pointer-events: none
        opacity: 0.65