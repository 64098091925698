.pitLogo {
    /* display:inline-block; */
    /* position:absolute; */
    right:0;
    width: 6rem;
    height: 1.7rem;
    margin: .3rem 1rem 1rem 0rem;
    padding: 0;
}

.logo {
   margin-left: 1rem;
   margin-bottom: .5rem;
}

h3 {
    color: white;
    padding: 0;
    margin: .3rem 1rem 1rem -6rem;
  }

.closeBtn {
   margin: .3rem 0rem .5rem .5rem;
   font-size: 1.5rem;
   padding: 0;
   color: white;
   background: none;        
    
}

#addBtnCont {
  text-align: right;
  padding-right: 1rem;
  width: 50px;
}


