.betting-history-container
  .betting-history-header
    padding: 15px 20px
    padding-bottom: 0
    p
      color: #D9D9D9
      font-family: $poppinsMediumFont
      font-size: 12px
  .betting-history-body
    padding: 0 20px 10px 20px
    border-bottom: 0.5px solid #5D5F64
    p
      color: #D9D9D9
      font-family: $poppinsRegularFont
      font-size: 12px
      line-height: 10px
    .betting-history-inititial-points, .betting-history-total-wins, .betting-history-total-lose, .betting-history-ending-total, .betting-history-difference
      display: flex
      justify-content: space-between
  .betting-history-table-container
    .betting-history-table
      thead
        font-size: 12px
        font-family: $poppinsMediumFont
        color: #E6E6E6
        th
          padding-left: 20px
          border-bottom: 0

      tbody
        color: #E6E6E6
        border-top: 0.5px solid #5D5F64
        tr 
          border-bottom: 0.5px solid #5D5F64
        td
          padding: 0 20px
          
      .betting-history
        margin-top: 20px
        p
          font-size: 10px
          font-family: $poppinsRegularFont
          color: #D9D9D9
          line-height: 3px
          text-transform: uppercase
        .betting-history-details
          .betting-history-fight-number, .betting-history-status, .betting-history-amount-result
            font-family: $poppinsMediumFont
            font-size: 12px
            .bet-wala
              color: #1E81F1
            .bet-meron
              color: #F34141
            .bet-draw
              color: #009900
            .bet-cancelled
              color: #fff
    .betting-history-pull-trigger
      text-align: center
      color: #FFCC00
      font-family: $poppinsRegularFont
      font-size: 12px
      display: flex
      justify-content: center

.no-betting-history-section
  margin: 50% auto
  text-align: center
  .no-betting-history-icon
      width: 65px
      height: auto
  .no-betting-history-message
    line-height: 5px
    margin-top: 20px
    p
      color: #fff
      font-family: $poppinsRegularFont
      font-size: 12px

.betting-history-loading
  color: #fff
  font-family: $poppinsRegularFont
  font-size: 16px
  display: flex
  justify-content: center