.operating-typography-header
    font-size: 15px
    color: #fff
    font-family: $poppinsLightFont
    text-align: center
.operating-typography-header2
    margin-top: 25px
    font-size: 15px
    color: #fff
    font-family: $poppinsBoldFont
    text-align: center
.operating-button
    margin-left: 50px
    margin-right:50px
.operating-container
    margin-top: 70px
    margin-left: 20px
    margin-right: 20px