.bet-fields-container
    padding: 10px 15px
    margin-bottom: 10%
    input[type='radio']
        position: fixed
        opacity: 0
        pointer-events: none
    .bet-fields-tab.active
        display: block
    .bet-fields-tab
        display: none
    .bet-fields-header
        display: flex
        justify-content: space-between
        .bet-fields-player-select
            color: #fff
            font-family: $poppinsRegularFont
            font-size: 14px
            height: 29px
            width: 146px
            text-align: center
            padding: 4px
            background-color: #1E2024
            border-radius: 3px
            margin-right: 10px
        .disabled
            border: 0.5px solid #5D5F64
            color: #5C5F66!important
        .bet-fields-player-bet
            color: #fff
            font-family: $poppinsRegularFont
            font-size: 10px
            padding: 8px
        .bet-fields-player-bet-value
            font-size: 12px!important
            margin: 0!important
        .bet-fields-player-multiple-bet-value
            font-size: 12px!important
            font-family: $poppinsMediumFont
            margin: 0!important
            color: #EE0000
        .btn-outline
            color: #fff
            font-family: $poppinsRegularFont
            font-size: 14px
            border: 0.5px solid #5D5F64
            height: 29px
            width: 146px
            padding: 4px !important
        .bet-fields-remove-container
            display: none
            .bet-fields-btn-remove
                color: #fff
                font-size: 10px
                font-family: $poppinsRegularFont
                text-decoration: none
                text-align: center
            .bet-fields-btn-remove.disabled
                opacity: 0.65
                border: none !important
                pointer-events: none!important
        .bet-fields-remove-container.active
            display: block
        .bet-fields-dropdown
            display: flex
    .bet-fields-custom-bet-container
        display: flex
        justify-content: space-between
        margin-bottom: 8px
        .bet-fields-cutom-bet-label
            font-family: $poppinsRegularFont
            font-size: 12px
            color: #fff
        .bet-fields-cutom-bet-label.disabled
            opacity: .65
        .bet-fields-custom-bet-input
            background-color: transparent
            border: none
            border-bottom: 0.5px solid #5D5F64
            text-align: center
            font-family: $poppinsRegularFont
            font-size: 12px
            color: #fff
            width: 30%
    .bet-fields-body
        padding-bottom: 12px
        .grid-btn.disabled
            pointer-events: none
            opacity: .65
        .grid-btn
            display: flex
            flex-flow: row wrap
            button
                flex: 1
                flex-basis: 17%
                text-align: center
            .button input[type='radio']
                &:checked + label.btn-meron
                    background: #dc3545
                    border-radius: 4px
            .button input[type='radio']
                &:checked + label.btn-wala
                    background: #1E81F1
                    border-radius: 4px
            .button input[type='radio']
                &:checked + label.btn-draw
                    background: #009900
                    border-radius: 4px
        .btn-bet
            color: #fff
            font-family: $poppinsMediumFont
            font-size: 11px
            width: 62px
            height: 28px
            border: 0.5px solid #5D5F64
            padding: 4px
        .btn-meron
            margin: 5px
            &:hover
                background-color: #dc3545
                border-color: #dc3545
            &:focus
                background-color: #dc3545
                border-color: #dc3545
        .btn-draw
            margin: 5px
            &:hover
                background-color: #009900
                border-color: #009900
            &:focus
                background-color: #009900
                border-color: #009900
        .btn-wala
            margin: 5px
            &:hover
                background-color: #1E81F1
                border-color: #1E81F1
            &:focus
                background-color: #1E81F1
                border-color: #1E81F1
        .btn-bet-group
            display: flex
            justify-content: space-evenly
            margin-bottom: 8px
            .button input[type='radio']
                &:checked + label.btn-meron
                    background: $meronColor
                    border-radius: 4px
            .button input[type='radio']:checked + label.btn-wala
                background: $walaColor
                border-radius: 4px
            .button input[type='radio']:checked + label.btn-draw
                background: $drawColor
                border-radius: 4px
                

.place-bet-button-container
    position: fixed
    bottom: 0
    width: 100%
    margin-bottom: 10px
    .place-bet-button-points-container
        display: flex
        justify-content: space-between
        .place-bet-button-points-label
            color: #fff
            font-family: $poppinsMediumFont
            font-size: 10px
            margin-bottom: 5px
    .btn-block
        color: #fff
        width: 100%
        font-family: $poppinsMediumFont
        font-size: 12px
    .place-bet-btn-meron
            background-color: #F34141
            .disabled
                color: #fff
    .place-bet-btn-draw
            background-color: #009900
            .disabled
                color: #fff
    .place-bet-btn-wala
            background-color: #1E81F1
            .disabled
                color: #fff

            
.swal2-actions
    width: 90%
    .swal-btn-cancel
        font-family: $poppinsMediumFont
        color: #EE0000
        font-size: 12px
    .swal-btn-confirm
        font-family: $poppinsMediumFont
        font-size: 12px
        background-color: #B31013
        color: #fff
        margin-bottom: 8px
        width: 100%

.swal2-html-container
    .success-message
        font-family: $poppinsBoldFont
        font-size: 13px
        .meron-tag
            font-weight: bold!important
            color: $meronColor
        .draw-tag
            font-weight: bold
            color: $drawColor
        .wala-tag
            font-weight: bold
            color: $walaColor