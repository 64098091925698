@use "sass:math"
.winning-list-container
  color: #ffffffff
  padding: 17px
  
  .winning-list-body
    margin-top:10px
    p
      margin: 0
      color: #5C5F66
      font-size: 12px
      font-family: $poppinsRegularFont
    .winning-list-header
    p.winning-list-name
      color: #ffffffff!important
      font-family: $poppinsMediumFont

  .winning-list-fight-number
    color: #ffffffff
    .bet-meron
      color: #F34141
    .bet-draw
      color: #009900
    .bet-wala
      color: #1E81F1
    .bet-cancelled
      color: #858991f2
  .winning-button-name
    width: 120px
    font-size: 12px!important
    .button-claim
      background-color: #2EB85C
      border-color:#2EB85C
  
  
  .btn:disabled
    width: 120px
    font-size: 12px
    color:#ffffffff
    background-color:#B7B7B7
    border-color:#B7B7B7
    opacity: 4.65

  .winning-input-search-bar
    border-radius: 20px
    border:0
    background-color:#ffffffff!important
    height: 27px
    font-size: 13px
    pointer:none
  .winning-list-hr
    margin-bottom: 7px
  .winning-list-tr
    border-color:#B7B7B7
    border-bottom: none
  .form-control:focus
    -webkit-box-shadow: none
    -moz-box-shadow: none
    box-shadow: none
  .winning-list-Headers
    color: #fff
    font-family: $poppinsRegularFont
    font-size: 17px
  
  .winning-list-action
    margin-top:30px
  
.claim-typography-header
    font-size: 15px
    font-family: $poppinsLightFont
    text-align: center
.claim-typography-header2
    margin-top: 25px
    font-size: 15px
    color: #fff
    font-family: $poppinsBoldFont
    text-align: center
.claim-button-body
    margin-left: 50px
    margin-right:50px
    .claim-button
      background-color:#FE0000!important
      border-color:#FE0000!important
    .claim-button-outline
      border:0px!important
      background-color:#ffffffff!important
      color:#FE0000
.claim-container
.claim-modal
  margin-top:50px 
