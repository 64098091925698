.side-drawer {
    height: 100%;
    width: 400px;
    background: rgb(223, 223, 223);
    position: fixed;
    left: 0%;
    z-index: 200;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);

}

.sidebarList {
  height: auto;
  padding: 0;
  width: 100%;
  margin-top: 20px;
}

.sidebarList .row {
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
}

.side-drawer a {
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  border-radius: 4px;
  align-items: center;

}
 
.side-drawer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

 .row #icon {
   flex: 30%;
   display: grid;
   place-items: center;
 }
 
 .row #title {
   flex: 70%;
   
 }

.side-drawer a:hover,
.side-drawer a:active {
  cursor: pointer;
  background-color: #6991b6;
}



@media screen and (max-width: 700px) {
  .side-drawer {
    width: 100%;
    height: 100%;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

.menuIcons {
  width: 2.5rem;
  height: 2.5rem;
}