.live-streaming-container
  width: 100%
  .live-streaming-video-container
    .iframe-container
      height: 238px
  .live-streaming-flash-details-banner
    display: flex
    height: 28px
    .live-streaming-icon
      width: 15%
      background-color: #B31013
      color: #fff
      text-align: center
      .video-icon
        width: 50%
        height: auto
    .live-streaming-flash-details
      width: 85%
      background-color: #EE0000
      color: #fff
      font-family: $poppinsRegularFont
      font-size: 14px
      padding: 4px
  .live-streaming-events-container
    padding: 15px
    .live-streaming-event-title, .live-streaming-event-description
      color: #fff
      font-family: $poppinsRegularFont
      font-size: 12px
      margin-bottom: 5px
      .live-streaming-label
        color: #1E8455
.trendsHeader
  display: flex
  background-color: #303337
  justify-content: left
  .trendsViewTitle
    padding: .8rem
    color: #fff
    font-family: $poppinsMediumFont
    font-size: 12px
  .toggleButtons
    .btnDisabled
      border: 1px solid #999999
      background-color: #303337
      color:  #FFFFFF
      border-radius: 20px
      margin: .8rem
      width: 100px
      height: 25px
      font-family: $poppinsMediumFont
      font-size: 12px
    .sequenceBtn
      margin: .8rem
      background-color: #46484c
      border-radius: 20px
      width: 100px
      color:  #FFFFFF
      height: 25px
      font-family: $poppinsMediumFont
      font-size: 12px
    .baccaratBtn
      margin: .8rem
      width: 100px
      background-color: #46484c
      border-radius: 20px
      color:  #FFFFFF
      height: 25px
      font-family: $poppinsMediumFont
      font-size: 12px
.trendsBody
  .totalpoints
    padding-left: 2rem
    padding-top: 1rem
    margin-left: 10px
    .dotWala, .dotMeron, .dotDraw, .dotCancelled
      border-radius: 50%
      width: 50px
      height: auto
      margin-right: 2rem
      background: blue
      border: 2px solid transparent
      color: #F9F6EE
      text-align: center
      font: 28px $poppinsRegularFont
      padding-top: 3px
    .dotMeron
      background-color: #b30e12
    .dotWala
      background-color: #0b4491
    .dotDraw
      background-color: #27a844
    .dotCancelled
      background-color: #6c757e
    .cirDescMeron, .cirDescWala,.cirDescDraw, .cirDescCancelled
      margin-top: 10px
      font-family: $poppinsMediumFont
      font-size: 12px
    .cirDescMeron
      color: white
    .cirDescWala
      color: white
      margin-left: 8px
    .cirDescDraw
      color: white
      margin-left: 7px
    .cirDescCancelled
      color: white
      margin-left: -3px
  
  .hidden
    display: none
  .sequenceContainer
    margin-top: 10px
    width: 100%
    display: flex
    flex-direction: row
    width: 400px
    z-index:3
    overflow: auto
    height:auto
    .sequenceBody
      position:fixed
      z-index: 10
      margin-top: 0px
      height:240px
      background: #fff
      background-size: 40px    
      background-image: repeating-linear-gradient(0deg, #9c9c9c, #9c9c9c 1px, transparent 1px, transparent 39.5px),repeating-linear-gradient(-90deg, #9c9c9c, #9c9c9c 1px, transparent 1px, transparent 40px)
      .dotWala, .dotMeron, .dotDraw, .dotCancelled
        border-radius: 50%
        width: 30px
        height: 30px
        margin: .6rem .5rem .5rem .2rem
        background: blue
        color: #F9F6EE
        text-align: center
        font: 15px $poppinsRegularFont
        padding-top: 3px
      .dotMeron
        background-color: #b30e12
      .dotWala
        background-color: #0b4491
      .dotDraw
        background-color: #27a844
      .dotCancelled
        background-color: #6c757e
  .baccaratContainer
    display: flex
    flex-direction: row
    width: 400px
    z-index:3
    overflow: auto
    .baccaratBody
      position:fixed
      z-index: 10
      border-radius: 4px
      margin-top: 0px
      height:240px
      background: #fff
      background-size: 40px    
      background-image: repeating-linear-gradient(0deg, #9c9c9c, #9c9c9c 1px, transparent 1px, transparent 40px),repeating-linear-gradient(-90deg, #9c9c9c, #9c9c9c 1px, transparent 1px, transparent 40px)
      .doughnutMeron, .doughnutWala, .doughnutDraw, .doughnutCancelled
        border-radius: 20px
        height:30px
        width:30px
        padding-top: 3px
        margin: .5rem .5rem .5rem .2rem
      .doughnutMeron
        border: 8px solid #b30e12
      .doughnutWala
        border: 8px solid #0b4491
      .doughnutDraw
        border: 8px solid #27a844
      .doughnutCancelled
        border: 8px solid #6c757e
      hr.solid 
        margin-top: 22px
        width: 70px
        margin-left: -17px
        border-top: 3px solid #000000
        
      

    
    

     
