@font-face
	font-family: 'poppins-regular'
	src: url('../fonts/Poppins-Regular.ttf')
@font-face
	font-family: 'poppins-light'
	src: url('../fonts/Poppins-Light.ttf')
@font-face
	font-family: 'poppins-medium'
	src: url('../fonts/Poppins-Medium.ttf')
@font-face
	font-family: 'poppins-bold'
	src: url('../fonts/Poppins-Bold.ttf')

body
	background: #1E2024

.spinn
	animation: spin
	animation-duration: 5000ms
	animation-iteration-count: infinite
	animation-timing-function: linear

.sticky
	position: fixed
	z-index: 999
	top: 0
	width: 100%

.sticky
	+ .bet-fields-container
		padding-top: 250px

@keyframes spin
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)
